import i18next from "i18next"
import qs from "qs"
import { SagaIterator } from "redux-saga"
import { all, call, put, takeLatest } from "redux-saga/effects"

import api from "../../api/api"
import { getExamsConfig } from "../../api/routes"
import { MAX_COMING_EVENTS_LIMIT } from "../settings/settings.config"
import { ExamModel, ExamStatus } from "../../components/exam/Exam.types"
import { redirectToError500Page } from "../../utils/handleErrors"

import {
  getComingExams,
  getExamsWithResults,
  setComingExams,
  setComingExamsError,
  setComingExamsTotal,
  setExamsWithResults, setExamsWithResultsError} from "./exams.slice"

async function getPaidExams(comingEventsLimit: number) {
  return api.request<{items: ExamModel[], total: number}>({
    ...getExamsConfig,
    params: {
      limit: comingEventsLimit,
      statuses: [ExamStatus.STATUS_PAID],
      lang: i18next.language
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: "brackets" })
    }
  })
}

function* getComingExamsSaga() {
  try {
    const {data} = yield call(getPaidExams, MAX_COMING_EVENTS_LIMIT)
    yield all([
      put(setComingExams(data.items)),
      put(setComingExamsTotal(data.total)),
    ])
  } catch (e) {
    yield put(setComingExamsError({
      data: e.response.data,
      status: e.response.status,
    }))
    yield call(redirectToError500Page, e)
  }
}

async function getExamsWithResultsRequest() {
  return api.request<{items: ExamModel[], total: number}>({
    ...getExamsConfig,
    params: {
      statuses: [ExamStatus.STATUS_RESULTS_AVAILABLE],
      lang: i18next.language
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: "brackets" })
    }
  })
}

function* getxExamsWithResultsSaga() {
  try {
    const {data} = yield call(getExamsWithResultsRequest)
    yield all([
      put(setExamsWithResults(data.items)),
    ])
  } catch (e) {
    yield put(setExamsWithResultsError({
      data: e.response.data,
      status: e.response.status,
    }))
    yield call(redirectToError500Page, e)
  }
}

export default function* (): SagaIterator {
  yield takeLatest(getComingExams, getComingExamsSaga)
  yield takeLatest(getExamsWithResults, getxExamsWithResultsSaga)
}

import { format } from "date-fns"
import i18n from "i18next"

import { getConsultationExamReferralsConfig, postExamCartConfig } from "../../api/routes"
import { v4Service } from "../store.service"
import { ConsultationExamReferral, UncoveredExam } from "../../components/exam/Exam.types"
import { RoutePath } from "../../routes/Routes.types"
import { RootState } from "../store.types"
import { ConsultationExamReferralsResponse, ExaminationOrder } from "./examReferrals.types"

export interface ExaminationOrderSuccessResponse {
  status: string;
  redirect: string;
  uncoveredExams?: UncoveredExam[];
}

const examReferralsApi = v4Service.injectEndpoints({
  endpoints: build => ({
    getExaminationReferrals: build.query<ConsultationExamReferral[], string>({
      query: (id) => ({
        ...getConsultationExamReferralsConfig(id)
      }),
      transformResponse: (response: ConsultationExamReferralsResponse) => response.referrals
    }),
    createExamOrder: build.mutation<ExaminationOrderSuccessResponse, ExaminationOrder>({
      async queryFn(examinationOrder, { getState }, extraOptions, baseQuery) {
        const state = (getState() as RootState)
        const patientId = state.user.userId
        const laboratoryId = state.clinic.clinicSettings.patientLaboratories[0]

        const { data, error } = await baseQuery({
          ...postExamCartConfig,
          data: {
            laboratory_id: laboratoryId,
            patient_id: patientId,
            consultation_id: examinationOrder.consultationId,
            language: examinationOrder.language,
            is_poz: false,
            payment_redirect_url: `${window.location.origin}/${i18n.language}${RoutePath.EXAMS_PAYMENT_SUCCESSFULL}`,
            execute_date: format(
              examinationOrder.executeDate
                ? new Date(examinationOrder.executeDate)
                : new Date(),
              "Y-MM-d"
            ),
            exams: examinationOrder.referrals.map(referral => Number(referral.id)),
            exam_point_id:  examinationOrder?.laboratory?.id ?? null,
          }
        })

        if (error) {
          return { error }
        }

        return { data: data as ExaminationOrderSuccessResponse }
      }
    })
  })
})

export const { useCreateExamOrderMutation, useGetExaminationReferralsQuery } = examReferralsApi
